import logo from './logo.svg';
import './App.css';
import {useState} from "react";

function App() {
  const [randomTilesFinal, setRandomTilesFinal] = useState([]);

  let randomTiles = [];
  let availableTiles = [
    {
      colorName: '#1C6C65',
      available: 27
    },
    {
      colorName: '#599EBF',
      available: 27
    },
    {
      colorName: '#52AF97',
      available: 27
    },
    {
      colorName: '#E3DDD6',
      available: 27
    },
    {
      colorName: '#FAE87F',
      available: 27
    },
    {
      colorName: '#D4C0A5',
      available: 27
    },
    {
      colorName: '#B73335',
      available: 27
    },
  ];

  const generate = () => {
    let random = Math.floor(Math.random() * 7);
    console.log(availableTiles[random]);
    let lineCount = 0;
    let tileLineArray = [];
    while(randomTiles.length !== 7) {
      if(lineCount === 27) {
        randomTiles.push(tileLineArray);
        lineCount = 0;
        tileLineArray = [];
      }
      let random = Math.floor(Math.random() * 7);
      if(availableTiles[random].available > 0) {
        tileLineArray.push(availableTiles[random].colorName);
        availableTiles[random].available--;
        lineCount++;
      }

    }
    console.log(randomTiles);
    setRandomTilesFinal(randomTiles);
  }
  return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-column justify-content-center align-content-center">
              <button onClick={generate}>Generate</button>
              <table border={0} cellSpacing={0}>
                {randomTilesFinal.map((tile) => {
                  return (
                      <tr>
                        {tile.map((tile) => {
                          return <td style={{backgroundColor: tile, height: '50px', width: '50px'}}></td>
                        })}
                      </tr>
                  )
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
  );
}

export default App;
